<template>
  <div class="WorkticketAnalysis has-nav-bar">
    <WorkticketHeader title="作业统计"></WorkticketHeader>
    <div class="select-box">
      <van-field
        v-model="filterLabel.department"
        left-icon="shouqi"
        readonly
        icon-prefix="iconfont"
        placeholder="所属部门"
        class="select-person__department"
        @click="departmentVisible = true"
      />

      <cascade-department
        v-model="department"
        :visible="departmentVisible"
        include-children
        @close="departmentVisible = false"
        @confirm="handlerSelectDepartment"
      />
    </div>

    <date-time
      v-model="dateTime"
      :time-close="false"
      type="date"
      format="{y}-{m}-{d}"
      @input="hanlderSelectTime"
    />

    <van-tabs v-model="active" swipeable :swipe-threshold="6">
      <van-tab title="作业状态"></van-tab>
      <van-tab title="作业类别"></van-tab>
      <van-tab title="作业数量"></van-tab>
      <van-tab title="作业区域"></van-tab>
      <van-tab title="作业单位"></van-tab>
    </van-tabs>

    <div v-if="active >= 2" class="query-box">
      <!--作业类别-->
      <div v-show="active == 2" class="line-box">
        <van-field
          v-model="filterLabel.workType"
          class="input-pull-down flex1"
          right-icon="shouqi"
          icon-prefix="iconfont"
          readonly
          placeholder="请选择作业类别"
          @click="$refs.typePicker.show()"
        />
        <van-field
          v-model="filterLabel.dateUnit2"
          class="input-pull-down date"
          right-icon="shouqi"
          icon-prefix="iconfont"
          readonly
          placeholder="日"
          @click="$refs.dateUnit2.show()"
        />
      </div>

      <!--作业区域-->
      <div v-show="active == 3" class="line-box">
        <van-field
          v-model="filterLabel.facilities"
          class="input-pull-down flex1"
          right-icon="shouqi"
          icon-prefix="iconfont"
          readonly
          placeholder="请选择作业区域"
          @click="$refs.areaPicker.show()"
        />
        <van-field
          v-model="filterLabel.dateUnit3"
          class="input-pull-down date"
          right-icon="shouqi"
          icon-prefix="iconfont"
          readonly
          placeholder="日"
          @click="$refs.dateUnit3.show()"
        />
      </div>

      <!--z作业单位-->
      <div v-show="active == 4">
        <van-tabs v-model="activeUnit" class="tabs-card" type="card">
          <van-tab title="合计"></van-tab>
          <van-tab title="企业"></van-tab>
          <van-tab title="承包商"></van-tab>
        </van-tabs>

        <div v-show="active == 4" class="line-box">
          <van-field
            v-show="activeUnit == 1"
            v-model="filterLabel.dept0"
            class="input-pull-down flex1"
            right-icon="shouqi"
            icon-prefix="iconfont"
            readonly
            placeholder="请选择作业单位"
            @click="departmentVisible1 = true"
          />
          <van-field
            v-show="activeUnit == 2"
            v-model="filterLabel.dept1"
            class="input-pull-down flex1"
            right-icon="shouqi"
            icon-prefix="iconfont"
            readonly
            placeholder="请选择作业单位"
            @click="$refs.dept1Picker.show()"
          />
          <van-field
            v-model="filterLabel.dateUnit4"
            class="input-pull-down date"
            :class="{ showTop: activeUnit == 0 }"
            right-icon="shouqi"
            icon-prefix="iconfont"
            readonly
            placeholder="日"
            @click="$refs.dateUnit4.show()"
          />
        </div>
      </div>
    </div>

    <div class="content-box">
      <div
        v-show="loadStatus == LOAD_STATUS.SUCCESS"
        id="chart"
        class="chart-box"
      />
      <LoadView
        v-model="loadStatus"
        class="LoadView"
        empty-text="暂无数据，切换筛选条件试试"
        @retry="loadChart(active)"
      />
    </div>

    <!--作业类别-->
    <CheckboxPicker
      ref="typePicker"
      v-model="query.workType"
      :label.sync="filterLabel.workType"
      title="作业类别"
      :options="workTypeOptions"
      option-value-key="id"
      option-label-key="name"
      @change="handlerCheckedChange"
    />

    <!--作业区域-->
    <WorkAreaPicker
      ref="areaPicker"
      v-model="query.facilities"
      :label.sync="filterLabel.facilities"
      :multiple="true"
      @change="handlerCheckedChange"
    />

    <!--年月日周-->
    <RadioPicker
      ref="dateUnit2"
      v-model="query.dateUnit2"
      :label.sync="filterLabel.dateUnit2"
      title="请选择"
      :options="$store.state.workticket.analysisDateUnitOptions"
      @change="handlerCheckedChange"
    />
    <RadioPicker
      ref="dateUnit3"
      v-model="query.dateUnit3"
      :label.sync="filterLabel.dateUnit3"
      title="请选择"
      :options="$store.state.workticket.analysisDateUnitOptions"
      @change="handlerCheckedChange"
    />
    <RadioPicker
      ref="dateUnit4"
      v-model="query.dateUnit4"
      :label.sync="filterLabel.dateUnit4"
      title="请选择"
      :options="$store.state.workticket.analysisDateUnitOptions"
      @change="handlerCheckedChange"
    />
    <CheckboxPicker
      ref="dept1Picker"
      v-model="query.dept1"
      :label.sync="filterLabel.dept1"
      title="承包商"
      :options="contractorsOptions"
      @change="handlerCheckedChange"
    />

    <cascade-department
      v-model="query.dept0"
      :visible="departmentVisible1"
      @close="departmentVisible1 = false"
      @confirm="handlerSelectDepartment0"
    />
  </div>
</template>

<script>
import WorkticketHeader from "@/components/header/WorkticketHeader";
import echarts from "echarts";
import { createLineOptions, createPieOptions } from "@/utils/echarts";
import CascadeDepartment from "@/components/CascadeDepartment";
import RadioPicker from "@/components/picker/RadioPicker";
import WorkAreaPicker from "@/components/picker/WorkAreaPicker";
import { getSelectContractorsNames } from "@/api/base";
import CheckboxPicker from "@/components/picker/CheckboxPicker";
import {
  getWorkCountNumAnalysis,
  getWorkDeptAnalysis,
  getWorkDeptNumAnalysis,
  getWorkNumAnalysis,
  getWorkPoiAnalysis,
  getWorkStatusAnalysis,
  getWorkTypeAnalysis
} from "@/api/workticket";
import { getRecentDays } from "@/utils";
import DateTime from "@/components/DatetimePicker";
import { LOAD_STATUS } from "@/components/loadview/utils";
import LoadView from "@/components/loadview/LoadView";
import { inspectResultTypes } from "@/views/trouble/utils/constant";

export default {
  name: "WorkticketAnalysis",
  components: {
    LoadView,
    DateTime,
    CheckboxPicker,
    WorkAreaPicker,
    RadioPicker,
    CascadeDepartment,
    WorkticketHeader
  },
  data() {
    return {
      loadStatus: LOAD_STATUS.LOADING,
      LOAD_STATUS,
      departmentVisible: false,
      departmentVisible1: false,
      filterLabel: {
        department: "",
        workType: "",
        facilities: "",
        dateUnit2: "",
        dateUnit3: "",
        dateUnit4: "",
        dept0: "",
        dept1: "",
        subDepartment: true
      },
      dateTime: getRecentDays(7),
      active: 0,
      activeUnit: 0,
      myChart: null,
      department: "",
      query: {
        department: "",
        startDate: "",
        endDate: "",
        contractorStatus: 0, //是否是承包商
        dept: "", //作业单位,多个逗号隔开
        dept0: undefined, //企业：作业单位,多个逗号隔开
        dept1: [], //承包商：作业单位,多个逗号隔开
        workType: [], //作业类别,多个逗号隔开
        facilities: [], //区域id, 多个逗号隔开
        dateUnit2: "", //作业数量：统计时间单位
        dateUnit3: "", //作业区域：统计时间单位
        dateUnit4: "" //作业单位：统计时间单位
      },
      contractorsOptions: []
    };
  },
  computed: {
    workTypeOptions: {
      get() {
        return this.$store.state.workticket.workTypeOptions;
      }
    },
    userInfo() {
      return this.$store.state.login.userInfo;
    }
  },
  watch: {
    active(newValue, oldValue) {
      this.loadChart(newValue);
    },
    activeUnit(newValue, oldValue) {
      this.query.dept = "";
      this.query.dept0 = "";
      this.query.dept1 = [];
      this.query.dateUnit4 = "";
      this.query.contractorStatus = newValue == 2 ? 1 : 0;
      this.filterLabel.dept0 = "";
      this.loadChart(this.active);
    }
  },
  created: function() {},
  mounted: function() {
    this.loadChart(0);
    this.getContractorsOptions();
    this.$store.dispatch("getWorkTypeOptions");
  },
  methods: {
    drawChart(options) {
      this.loadStatus = LOAD_STATUS.SUCCESS;
      this.$nextTick(() => {
        this.myChart = echarts.init(
          document.getElementById("chart", null, {
            renderer: "svg"
          })
        );
        this.myChart.clear();
        this.myChart.setOption(options);
      });
    },
    hanlderSelectTime() {
      if (!this.dateTime.start || !this.dateTime.end) return;

      this.resetQuery();
      this.loadChart(this.active);
    },
    handlerSelectDepartment(selectValue, includeInfo) {
      this.query.department = this.department;
      this.query.subDepartment = includeInfo?.include;
      this.filterLabel.department = selectValue?.label || "";
      this.resetQuery();
      this.loadChart(this.active);
    },
    handlerSelectDepartment0({ label }) {
      this.filterLabel.dept0 = label;
      this.loadChart(this.active);
    },

    handlerCheckedChange() {
      this.loadChart(this.active);
    },
    resetQuery() {
      this.query.contractorStatus = 0;
      this.query.dept = "";
      this.query.dept0 = "";
      this.query.dept1 = [];
      this.query.subDepartment = true;
      this.query.workType = [];
      this.query.facilities = [];
      this.query.dateUnit2 = "";
      this.query.dateUnit3 = "";
      this.query.dateUnit4 = "";
      this.filterLabel.dept0 = "";

      this.activeUnit = 0;
    },
    loadChart(active = 0) {
      this.loadStatus = LOAD_STATUS.LOADING;
      switch (active) {
        case 0:
          this.loadWorkStatusChart();
          break;
        case 1:
          this.loadWorkTypeChart();
          break;
        case 2:
          this.loadWorkNum();
          break;
        case 3:
          this.loadWorkArea();
          break;
        case 4:
          this.loadWorkUnit();
          break;
      }
    },

    getCommParams() {
      let { query, dateTime } = this;
      return {
        department: query.department,
        startTime: dateTime.start && dateTime.start + " 00:00:00",
        endTime: dateTime.end && dateTime.end + " 23:59:59"
      };
    },
    loadWorkStatusChart() {
      console.log("loadWorkStatusChart---");
      getWorkStatusAnalysis({
        ...this.getCommParams(),
        orgCode: this.userInfo.orgCode
      })
        .then(res => {
          if (!res || res.length == 0) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }
          this.drawChart(createPieOptions(res));
        })
        .catch(error => {
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    loadWorkTypeChart() {
      console.log("loadWorkTypeChart---");
      getWorkTypeAnalysis({
        ...this.getCommParams(),
        orgCode: this.userInfo.orgCode
      })
        .then(res => {
          if (!res || res.length == 0) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }
          this.drawChart(createPieOptions(res));
        })
        .catch(error => {
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    loadWorkNum() {
      console.log("loadWorkNum---");

      let { query } = this;
      let params = {
        ...this.getCommParams(),
        workType: Array.isArray(query.workType)
          ? query.workType.join(",")
          : query.workType,
        dateUnit: query.dateUnit2,
        orgCode: this.userInfo.orgCode
      };

      let fn = params.workType ? getWorkNumAnalysis : getWorkCountNumAnalysis;

      fn(params)
        .then(res => {
          if (!res || !res.data || res.data.length == 0) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }
          this.drawChart(createLineOptions(res.columns, res.data));
        })
        .catch(error => {
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    loadWorkArea() {
      console.log("loadWorkArea---");
      let { query } = this;
      let params = {
        ...this.getCommParams(),
        facilities: query.facilities.join(","),
        dateUnit: query.dateUnit3,
        orgCode: this.userInfo.orgCode
      };

      let fn = params.facilities ? getWorkPoiAnalysis : getWorkCountNumAnalysis;

      fn(params)
        .then(res => {
          if (!res || !res.data || res.data.length == 0) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }
          this.drawChart(createLineOptions(res.columns, res.data));
        })
        .catch(error => {
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    loadWorkUnit() {
      console.log("loadWorkUnit---");
      let { query, activeUnit } = this;

      let params = {
        ...this.getCommParams(),
        dept: { 0: "", 1: query.dept0, 2: query.dept1.join(",") }[activeUnit],
        dateUnit: query.dateUnit4,
        contractorStatus: { 0: "", 1: 0, 2: 1 }[activeUnit],
        orgCode: this.userInfo.orgCode
      };

      let fn = params.dept ? getWorkDeptAnalysis : getWorkDeptNumAnalysis;

      fn(params)
        .then(res => {
          if (!res || !res.data || res.data.length == 0) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }
          let seriesData = [];
          if (params.dept) {
            seriesData = res.data;
          } else if (activeUnit == 0) {
            seriesData = [res.data[0]];
          } else if (activeUnit == 1) {
            seriesData = [res.data[1]];
          } else if (activeUnit == 2) {
            seriesData = [res.data[2]];
          }
          this.drawChart(createLineOptions(res.columns, seriesData));
        })
        .catch(error => {
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },

    getContractorsOptions() {
      getSelectContractorsNames({
        orgCodeInList: this.userInfo.orgCode
      }).then(res => {
        this.contractorsOptions = res;
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkticketAnalysis {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;

  .select-box {
    padding: 16px 16px 0px 16px;
    box-sizing: border-box;

    .select-person__department {
      margin-bottom: 0px;
    }
  }

  .query-box {
    padding: 20px 16px 5px 16px;
  }

  .line-box {
    position: relative;
    display: flex;

    .flex1 {
      flex-grow: 1;
    }

    .date {
      flex-shrink: 0;
      width: 60px;
      margin-left: 15px;
    }

    .showTop {
      position: absolute;
      right: 0px;
      top: -46px;
      padding: 5px 10px;
    }
  }

  .tabs-card {
    width: 160px;
    margin-bottom: 16px;
  }

  .content-box {
    position: relative;
    flex-grow: 1;

    .chart-box {
      width: 100vw;
      height: 60vh;
    }

    .LoadView {
      /*position: absolute;*/
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 10;
    }
  }

  ::v-deep .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border: none;
  }
}
</style>
