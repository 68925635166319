export const createPieOptions = (seriesData = []) => {
  return {
    color: [
      "#F46B6B",
      "#FA8F51",
      "#EFD358",
      "#9ED05A",
      "#42C38A",
      "#4CC2F8",
      "#4596F0",
      "#6D7BF7",
      "#A072D9"
    ],
    legend: {
      width: "90%",
      icon: "roundRect",
      // bottom: 20,
      top: "60%",
      itemWidth: 12,
      itemHeight: 12,
      itemGap: 18,
      formatter: function(name) {
        return name;
      },
      textStyle: {
        lineHeight: 10,
        rich: {
          a: {
            // 没有设置 `lineHeight`，则 `lineHeight` 为 56
          }
        }
      },
      data: seriesData.map(item => item.name)
    },
    tooltip: {
      padding: [5, 10],
      trigger: "item",
      backgroundColor: "rgb(255,255,255)",
      textStyle: {
        color: "rgb(52,57,78)",
        fontSize: 12
      },
      formatter: "{b} : {c} ({d}%)",
      extraCssText: "box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);",
      axisPointer: {
        z: -1
      }
    },
    grid: {
      top: "10",
      left: "10",
      right: "10",
      bottom: "10",
      containLabel: true
    },
    series: [
      {
        type: "pie",
        top: "top",
        center: ["50%", "30%"],
        radius: ["30%", "50%"],
        clockwise: true,
        avoidLabelOverlap: true,
        hoverOffset: 15,
        itemStyle: {
          normal: {
            borderColor: "#fff",
            borderWidth: 1
          }
        },
        label: {
          show: true,
          position: "outside",
          formatter: "{b}  {c}",
          fontSize: 10,
          color: "rgb(59,70,100)"
        },
        labelLine: {
          normal: {
            length: 6,
            length2: 10,
            lineStyle: {
              width: 1
            }
          }
        },
        data: seriesData
      }
    ]
  };
};

export const createLineOptions = (xaxis = [], seriesData = []) => {
  let color = [
    "#4596F0",
    "#42C38A",
    "#F46B6B",
    "#FA8F51",
    "#EFD358",
    "#9ED05A",
    "#4CC2F8",
    "#6D7BF7",
    "#A072D9"
  ];

  let nameOverWidth = (xaxis[0] || "").indexOf("~") != -1;

  return {
    color: color,
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgb(255,255,255)",
      textStyle: {
        color: "rgb(52,57,78)",
        fontSize: 12
      },
      padding: [5, 10],
      extraCssText: "box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);",
      axisPointer: {
        z: -1,
        lineStyle: {
          color: "#ddd"
        }
      }
    },
    legend: {
      icon: "roundRect",
      top: "65%",
      itemGap: 18,
      itemWidth: 12,
      itemHeight: 12,
      data: seriesData.map(item => item.name)
    },
    grid: {
      left: "20",
      right: "40",
      bottom: "40%",
      containLabel: true
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      axisTick: {
        show: true
      },
      axisLabel: {
        rotate: nameOverWidth ? 0 : 60,
        fontSize: 10,
        color: "rgb(59,70,100)",
        formatter: function(value, index) {
          return paramsDataName(value);
        }
      },
      axisLine: {
        lineStyle: {
          width: 0.5,
          color: "rgb(199,201,208)"
        }
      },
      data: xaxis
    },
    yAxis: {
      type: "value",
      axisTick: {
        show: false
      },
      axisLabel: {
        fontSize: 10
      },
      axisLine: {
        show: false
      },
      splitLine: {
        lineStyle: {
          type: "dashed",
          width: 0.5,
          color: "rgb(199,201,208)"
        }
      }
    },
    series: seriesData.map((item, index) => {
      return {
        name: item.name,
        type: "line",
        // stack: "总量",
        data: item.data || [],
        symbol: "circle",
        itemStyle: {
          color: color[index]
        },
        emphasis: {
          itemStyle: {
            color: {
              type: "radial",
              x: 0.5,
              y: 0.5,
              r: 0.5,
              colorStops: [
                { offset: 0, color: color[index] },
                { offset: 0.5, color: color[index] },
                { offset: 0.9, color: "#Fff" },
                { offset: 1, color: "#fff" }
              ],
              global: false // 缺省为 false
            },
            borderColor: "#fff",
            shadowColor: color[index],
            shadowBlur: 8
          }
        }
      };
    })
  };
};

function paramsDataName(value) {
  let index = value.indexOf("~");
  if (index == -1) return value;
  return (
    value.substring(0, index) +
    "\n~\n" +
    value.substring(index + 1, value.length)
  );
}

/**
 *
 * @param params
 * @param provideNumber 每行能显示的字的个数
 * @returns {string}
 */
function paramsName(params, provideNumber = 11) {
  let newParamsName = ""; // 最终拼接成的字符串
  let paramsNameNumber = params.length; // 实际标签的个数
  let rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
  /**
   * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
   */
  // 条件等同于rowNumber>1
  if (paramsNameNumber > provideNumber) {
    /** 循环每一行,p表示行 */
    for (let p = 0; p < rowNumber; p++) {
      let tempStr = ""; // 表示每一次截取的字符串
      let start = p * provideNumber; // 开始截取的位置
      let end = start + provideNumber; // 结束截取的位置
      // 此处特殊处理最后一行的索引值
      if (p == rowNumber - 1) {
        // 最后一次不换行
        tempStr = params.substring(start, paramsNameNumber);
      } else {
        // 每一次拼接字符串并换行
        tempStr = params.substring(start, end) + "\n";
      }
      newParamsName += tempStr; // 最终拼成的字符串
    }
  } else {
    // 将旧标签的值赋给新标签
    newParamsName = params;
  }
  //将最终的字符串返回
  return newParamsName;
}
